export const environment = {
  COMPANY_NAME: "evollo",
  APP_NAME: "ADMIN",
  production: true,
  apiUrl: "https://tokiomarine-account.api.evollo.cloud",
  qualityApiUrl: "https://tokiomarine-quality.api.evollo.cloud",
  apps: {
    id: "https://tokiomarine.id.evollo.com.br/",
    quality: "https://tokiomarine.quality.evollo.com.br",
  },
  features: {
    enabledChat: false,
    enableOrgCreation: false,
	  enableSupport: false,
	  enableFaq: false,
    enableMicrosoftLogin: false
  },
};
